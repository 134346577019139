import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { ClientOnly, ExternalEmbed } from '../components/lib';

const DonatePage = ({ className }) => (
  <Layout>
    <SEO
      title='Donate'
      keywords={[
        `fathering.me`,
        `fatherlessness`,
        `new father`,
        `unplanned`,
        `donate`,
      ]}
    />
    <ClientOnly className={className}>
      <ExternalEmbed
        title='donate to fathering.me'
        src={process.env.GATSBY_DONATE_URL}
        scrolling='no'
        frameborder='0'
        border='0'
      />
    </ClientOnly>
  </Layout>
);

export default DonatePage;
